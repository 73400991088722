import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import "./index.scss"
import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import ethicsStoryImg from "../../../assets/images/ethics_story01.jpg"
import aboutUsImg from "../../../assets/images/sm_circle_AboutUs.png"
import headerHero from "../../../assets/images/header_ethics.png"

const pageTitle = "Ethics and Compliance | Harmony Biosciences"
const pageDescription =
  "Harmony Biosciences believes in transparency, collaboration and respect in our interactions. Ethics guide our daily efforts. Learn more about our approach here."

class ethics extends Component {
  constructor() {
    super()
  }

  ethicsBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "www.harmony.ethicspoint.com"
      )
    }
    window.open(
      "https://secure.ethicspoint.com/domain/media/en/gui/55521/index.html",
      "_blank"
    )
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="ethics-compliance">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords=""
            slug="/about-us/ethics-and-compliance"
          />
          <Hero bg={headerHero}>
            <h1>Ethics and Compliance</h1>
          </Hero>
          <ContentBlock color="blue">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <p>
                  <strong>Harmony Biosciences</strong> believes in transparency,
                  collaboration, and respect in our interactions with one
                  another. Further, we have a culture of ethics and pride
                  ourselves in doing things the right way. Consistent with our
                  open door and whistleblower policies, employees and others
                  should report any good faith concerns that they may have about
                  questionable or unethical behavior, or violations of law or
                  policy.
                </p>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock>
            <Row>
              <Col xs={12} md={6}>
                <img
                  src={ethicsStoryImg}
                  style={{ marginBottom: 20 }}
                  alt="Know Narcolepsy"
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  To enhance communication and allow for anonymous reporting,
                  Harmony Biosciences has implemented a phone and internet-based
                  reporting system, which is managed through{" "}
                  <strong>NAVEX Global’s EthicsPoint system</strong>.{" "}
                </p>
                <p>
                  The contact information for this system is: <br />
                  <strong>Website - </strong>
                  <a
                    href="javascript:void(0)"
                    onClick={this.ethicsBtn}
                    target="_blank"
                  >
                    www.harmony.ethicspoint.com
                  </a>
                </p>
                <p>
                  <strong>
                    Corporate Compliance Hotline -{" "}
                    <span className="txt-green">844-696-3453</span>
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <p className="disclaimer">NOTICE: This information is provided in accordance with the requirements of California Health &amp; Safety Code, Section 119402, which requires pharmaceutical companies doing business in California to make available their Compliance Program and annual written declaration of compliance with the Compliance Program.</p>
	<p className="disclaimer">In accordance with a good faith understanding of the legal requirements of California Health and Safety Code § 119402, Harmony has adopted a Comprehensive Compliance Program with respect to our business and operations in the State of California as mandated by this California law. This Comprehensive Compliance Program is tailored to Harmony's current size and business operations and Harmony continues to develop and implement changes to the program as compliance and operational needs evolve.</p>
	<p className="disclaimer">Pursuant to California Health &amp; Safety Code § 119402(d)(1), Harmony has established an annual dollar limit of $2,500 on meals associated with educational presentations, educational items, promotional materials and or other items of value that Harmony may provide to medical or health care professionals in California, as defined in the California statute.</p>
	<p className="disclaimer">Pursuant to California Health &amp; Safety Code § 119402(d)(2) and (3), financial support for continuing medical education forums, financial support for health educational scholarships, and fair market value payments made for legitimate services provided by a health care medical professional to Harmony, including but not limited to consulting, have been excluded from the spending limit.</p>
	<p className="disclaimer">Pursuant to California Health &amp; Safety Code § 119402(e) Harmony declares that it is in compliance with both its Comprehensive Compliance Program and this code. For additional information about Harmony's compliance program, contact 1-800-833-7460.</p>

              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default ethics
